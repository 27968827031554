import Lang from "@/resources/js/components/Lang";


export default {
    name: "users-registration-form",
    mixins: [Lang],

    data: function () {
        return {
            errors: [],
            form: {
                first_name: null,
                last_name: null,
                email: null,
                password: null,
            }
        }
    },
    methods: {
        checkForm: function (e) {
            if (this.form.email) {
                return true;
            }
            this.errors = [];

            if (!this.form.email) {
                this.errors.push('Invalid email');
            }
            e.preventDefault();
        },
        addUser: function () {
            this.$http.post('http://api.jazzforsoul.loc/registration', this.form/*, {responseType: 'blob'}*/)
                .then(response => {
console.log(response);
                })
                .catch(error => {
                    for (let key in error.response.data.error.context) {
                        this.errors.push(error.response.data.error.context[key]);
                    }
console.log(error.response);
                });

        }
    },
}